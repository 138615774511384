import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { Hooks, register } from 'register-service-worker';
import { PlatformService } from './platform.service';
import { CookieService } from 'ngx-unificator/services';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private readonly POPUP_COOKIE_NAME = 'appPopup';
  private readonly POPUP_DELAY = 8000;

  private _deferredPrompt = null;
  private _isEnabled = null;

  private _isPopupOpen: boolean;
  private _isPopupAvailable: boolean;

  constructor(
    private _platform: PlatformService,
    private _cookie: CookieService
  ) {
    this._beforeInstallPrompt();
  }

  get isEnabled(): boolean {
    return this._isEnabled;
  }

  get isPopupAvailable(): boolean {
    return this._isPopupAvailable;
  }

  get isPopupOpen(): boolean {
    return this._isPopupOpen;
  }


  public get deferredPrompt() {
    return this._deferredPrompt;
  }

  public openPopup() {
    this._isPopupOpen = true;
  }

  public closePopup() {
    this._isPopupOpen = false;

    this._cookie.set(this.POPUP_COOKIE_NAME, '1', 5, '/');
  }

  /**
   * Install PWA app
   */
  public addAppToHomeScreen() {
    this._deferredPrompt.prompt();
    this._deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        this._isEnabled = false;
      } else {
        console.log('User dismissed the A2HS prompt');
        this._isEnabled = true;
      }
    });
  }

  /**
   * Register Service Worker
   */
  public registerServiceWorker(): void {
    if (!this._platform.isBrowser) {
      return;
    }

    const serviceWorkerPath = `/sw.js`;
    const options: Hooks = {
      registrationOptions: { scope: './' },
      ready: (registration) => {
        console.log('Service worker is active.');
      },
      registered: (registration) => { },
      cached: (registration) => { },
      updatefound: (registration) => { },
      updated: (registration) => { },
      offline: () => {
        console.error('No internet connection found. App is running in offline mode.')
      },
      error: (error) => {
        console.error('Error during service worker registration:', error)
      }
    };
    register(serviceWorkerPath, options);
  }

  private _beforeInstallPrompt() {
    if (this._platform.isBrowser) {
      fromEvent(window, 'beforeinstallprompt').subscribe((e: any) => {
        e.preventDefault();
        this._deferredPrompt = e;
        this._isEnabled = true;

        if (!this._cookie.check(this.POPUP_COOKIE_NAME)) {
          this._isPopupAvailable = true;

          setTimeout(() => {
            this.openPopup();
          }, this.POPUP_DELAY);
        }
      });
    }
  }
}
